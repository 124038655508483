import type { MultiStyleConfig } from "@chakra-ui/theme-tools";
import { theme } from "@chakra-ui/theme";

const Table: Partial<MultiStyleConfig> = {
  baseStyle: {},
  defaultProps: {
    variant: "blueStriped",
  },

  variants: {
    nested: (props) => ({
      table: {
        ...theme.components.Table.baseStyle?.table,
        borderRadius: 6,
        boxShadow: "xs",
        overflow: "hidden",
      },
      td: {
        // Include all striped variant styles in our version. This is specifically needed to make isNumeric prop work with out variant but probably helps with other stuff too
        ...theme.components.Table.variants?.striped(props).td,
      },
      th: {
        // Include all striped variant styles in our version. This is specifically needed to make isNumeric prop work with out variant but probably helps with other stuff too
        ...theme.components.Table.variants?.striped(props).th,
      },
      thead: {
        background: "table.subHeader",
      },
      tbody: {
        ...theme.components.Table.variants?.striped(props).tbody,
        background: "white",
        tr: {
          borderBottomWidth: "1px",
          borderBottomColor: "#e6edf1",
          "&:nth-of-type(even)": {
            backgroundColor: "#fafbfc",
          },
        },
      },
      tfoot: {
        ...theme.components.Table.variants?.striped(props).tfoot,
        background: "#fafbfc",
        tr: {
          borderBottomWidth: "1px",
          borderBottomColor: "#e6edf1",
          "&:nth-of-type(even)": {
            backgroundColor: "white",
          },
        },
      },
    }),
    blueStriped: (props) => ({
      table: {
        ...theme.components.Table.baseStyle,
      },
      td: {
        // Include all striped variant styles in our version. This is specifically needed to make isNumeric prop work with out variant but probably helps with other stuff too
        ...theme.components.Table.variants?.striped(props).td,
        border: 0,
        color: "blackAlpha.900",
      },
      th: {
        // Include all striped variant styles in our version. This is specifically needed to make isNumeric prop work with out variant but probably helps with other stuff too
        ...theme.components.Table.variants?.striped(props).th,
      },
      thead: {
        background: "table.subHeader",
      },
      tbody: {
        background: "white",
        tr: {
          borderBottomWidth: "1px",
          borderBottomColor: "#e6edf1",
          "&:nth-of-type(even)": {
            backgroundColor: "#fafbfc",
          },
        },
      },
    }),
  },
};

export default Table;
