const Input = {
  defaultProps: {
    size: "md",
    variant: "filled",
  },
  variants: {
    filled: {
      field: {
        background: "#edf2f7",
        _focus: {
          background: "#edf2f7",
        },
      },
    },
    outline: {
      field: {
        borderColor: "blue.300",
        _hover: {
          color: "gray.700",
        },
      },
    },
  },
  baseStyle: {
    field: {
      color: "gray.900",
    },
  },
};

export default Input;
