import { extendTheme } from "@chakra-ui/react";
import Button from "styles/components/button";
import Drawer from "styles/components/drawer";
import Modal from "styles/components/modal";
import Input from "styles/components/input";
import Badge from "styles/components/badge";
import EaiCard from "styles/components/eaicard";
import Table from "styles/components/table";
import Select from "styles/components/select";
import Checkbox from "./components/checkbox";
import WebContentContainer from "./components/webcontentcontainer";
import Banner from "./components/banner";

export const rawTheme = {
  config: {
    cssVarPrefix: "eai",
  },

  fonts: {
    heading: "Avenir LT",
    body: "Avenir LT",
  },

  colors: {
    transparent: {
      20: "#00000033",
    },
    blue: {
      50: "#E5F4FF",
      100: "#B8E0FF",
      200: "#8ACDFF",
      300: "#5CB9FF",
      400: "#2EA5FF",
      500: "#0092FF",
      600: "#0075CC",
      700: "#005799",
      800: "#003C69",
      900: "#001D33",
    },
    green: {
      50: "#e9f9e6",
      100: "#cce8c6",
      200: "#acd7a5",
      300: "#8dc783",
      400: "#6eb761",
      500: "#549e48",
      600: "#407b37",
      700: "#2d5826",
      800: "#193615",
      900: "#011400",
    },
    orange: {
      50: "#ffefdd",
      100: "#fed3b2",
      200: "#f9b786",
      300: "#f49c57",
      400: "#f07f28",
      500: "#d7660f",
      600: "#a84f0a",
      700: "#783805",
      800: "#4a2100",
      900: "#1f0800",
    },
    pink: {
      50: "#FBE9F0",
      100: "#F4C2D5",
      200: "#ED9CBA",
      300: "#E6759E",
      400: "#DF4E83",
      500: "#D82768",
      600: "#AD1F53",
      700: "#82173F",
      800: "#56102A",
      900: "#2B0815",
    },
    violet: {
      50: "#f5eefa",
      100: "#dbcee3",
      200: "#c1aecf",
      300: "#a98ebc",
      400: "#916da8",
      500: "#775490",
      600: "#5c4170",
      700: "#422f50",
      800: "#281c31",
      900: "#0f0814",
    },
    primary: {
      dark: "#003C69", // blue.800
    },
    link: "#003C69", // blue.800
    black: "#1B1B1B",
    white: "#fff",
    text: "#1B1B1B",
    background: "#fff",
    backgroundColors: {
      lightBlue: "#DFE9EE",
    },
    brand: {
      blueHighlight: "#E6EDF1",
      blueSelected: "#e6eef1",
      blue: "#4B6E83",
      blueBackground: "#E6EDF1",
      orangeBackground: "#F07E2660",
      violetBackground: "#D6C8E060",
    },
    reportColors: {
      account: "#E6EDF1",
      transaction: "#EBE0F3",
      savings: "#FEF2EA",
      income: "#FFFFFF",
      participantAccount: "#E5EBEE",
    },
    timeLineChartColors: {
      yAxis: "#E6EDF1",
    },
    timelinePeriodColors: {
      boardSetsEarningCriteria: "#003C69",
      performancePeriod: "#8DA3B0",
      optionVestingPeriod: "#ACC7D7",
      ownershipRequirement: "#F07E26",
      sharePurchases: "#FBD9BE",
      measureOfAchievement: "#BD225B",
      optionDelivery: "#666666",
      savings: "#E6E6E6",
      vestingPeriodRequirement: "#58A54B",
      subscriptionPeriod: "#86C07C",
      sharePurchasesEssp: "#C0E1BB",
      shareDelivery: "#AD91C1",
      optionExpirationDate: "#D6C8E0",
      dividendAdjustment: "#F2EDF5",
    },
    sharePriceGraphColors: {
      grid: "#E6EDF1",
    },
    corporateActionTypeColors: {
      bonusIssue: "#FEF2EA",
      bonusShare: "#8DA3B0",
      changeOfTradecode: "#ACC7D7",
      combination: "#F9B786",
      dividend: "#FBD9BE",
      capitalReturn: "#E5EBEE",
      shareDividend: "#FFFFFF",
      spinOff: "#E6E6E6",
      exchange: "#8ACDFF",
      rightDistribution: "#ACD7A5",
      subscription: "#C0E1BB",
      merger: "#AD91C1",
      tenderOffer: "#D6C8E0",
      reverseSplit: "#F2EDF5",
      split: "#E6EDF1",
      salesOfSecurity: "#EBE0F3",
      vacuuming: "#F4C2D5",
    },
    badgeColors: {
      bluePill: {
        color: "#4B6E83",
        background: "#E6EDF1",
      },
      greenPill: {
        color: "#428a33",
        background: "#E6F8E3",
      },
      violetPill: {
        color: "#8d669f",
        background: "#F2EDF5",
      },
      redPill: {
        color: "#AB5E78",
        background: "#F2D3DE",
      },
      yellowPill: {
        color: "#807A2A",
        background: "#FAF7CA",
      },
    },
    recentActionsColors: {
      headerBackground: "#C5D8E3",
      background: "#E6EDF1",
    },
    custodyAccountColors: {
      portfolio: "#CEDDE8",
      esspPortfolio: "#E2D9E8",
      accountRow: "#E6EDF1",
    },
    savingsColors: {
      footer: "#E6EDF1",
    },
    table: {
      subHeader: "#eff3f5",
      evenBackground: "white",
      oddBackground: "#fafbfc",
      rowBorder: "#e6edf1",
      footerBorder: "#003c69",
      hover: "#e6eef3",
      header: "#ACC7d7",
    },
    graph: {
      blue: "#003C69",
      orange: "#F07E26",
      violet: "#AD91C1",
      green: "#58A54B",
      lightOrange: "#f9b786",
    },
    dateRangeBadge: {
      font: "#8DA3B0",
      border: "#ACC7D7",
    },
    myDetails: {
      cardHeader: "#CDDDE7",
      sidebarBackground: "#f3f6f8",
    },
    postLogin: {
      bluePrimary: "#ACC7D760",
      blueSecondary: "#E6EDF1",
      orangePrimary: "#F07E2660",
      orangeCircle: "#F07E26",
      greenText: "#58A54B",
      grayText: "#8DA3B0",
    },
    dashboardHeaders: {
      blue: "#D8E4EC",
      green: "#A8D49C",
      orange: "#F9C69E",
      orangeIcon: "#F07E26",
      violet: "#E8DCEC",
      yellow: "#FFDC9C",
    },
    dashboardCards: {
      blue: "#E8ECF4",
      green: "#E8FCE4",
      orange: "#FFF4EC",
      yellow: "#FFF4DC",
    },
    donutGraph: [
      "#003C69",
      "#F07E26",
      "#764897",
      "#8DA3B0",
      "#ACC7D7",
      "#E6EDF1",
    ],
    shareRegister: {
      blueHeader: "#C0D4DC",
      blueBackground: "#E5EDF0",
      greenHeader: "#B0D4A4",
      greenBackground: "#E6F7E4",
    },
    translucentWhite: "rgba(255, 255, 255, 0.9)",
  },
  components: {
    Button,
    Drawer,
    Modal,
    Input,
    Badge,
    EaiCard,
    Table,
    Select,
    Checkbox,
    WebContentContainer,
    Banner,
  },

  multiselect: {
    container: {
      border: 0,
      background: "transparent",
    },
    valueContainer: {
      background: "white",
      paddingLeft: 2,
      width: "100%",
      minHeight: "40px",
    },
    control: {
      border: "1px solid",
      borderRadius: 6,
      borderColor: "#eaeaea",
    },
    menu: {
      zIndex: 2,
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25)",
      mt: "2px",
    },
    dropdownIndicator: {
      background: "white",
    },
  },
};

export default extendTheme(rawTheme);
