import { Box } from "@chakra-ui/react";
import { BackgroundDarkening } from "apollo/generatedTypes";
import Image from "next/image";
import { useState } from "react";

const BackgroundImage = ({
  company,
  backgroundDarkening,
}: {
  company?: string;
  backgroundDarkening?: BackgroundDarkening;
}) => {
  const bgImage = company
    ? `/api/download/backgroundimage?filePath=/images/${company}/background.jpg`
    : "/images/bg_generic.jpg";

  const [background, handleBackground] = useState(bgImage);

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      zIndex="0"
      backgroundColor="primary.dark"
    >
      <Image
        src={background}
        onError={() => {
          // This is triggered if backgroundimage api returns an error (usually 404)
          handleBackground("/images/bg_generic.jpg");
        }}
        fill={true}
        style={{
          objectFit: "cover",
          objectPosition: "center",
          filter: backgroundDarkening
            ? backgroundDarkening === BackgroundDarkening.Light
              ? `brightness(70%)`
              : `brightness(40%)`
            : "none",
        }}
        alt={
          company
            ? `Background image for ${company}`
            : "Incentive default background image"
        }
        className="app_background"
        priority={true}
      />
    </Box>
  );
};

export default BackgroundImage;
