import { ComponentStyleConfig } from "@chakra-ui/react";

const Button: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: "semibold",
    textTransform: "uppercase",
    borderRadius: "base",
    lineHeight: "inherit",
  },
  sizes: {
    sm: {
      fontSize: "sm",
      px: 8,
      py: 4,
    },
    md: {
      fontSize: "sm",
      px: 8,
      py: 4,
      h: 7,
    },
  },
  variants: {
    solidBlue: (props) => {
      return {
        ...props.theme.components.Button.variants.solid,
        color: "white",
        bg: "primary.dark",
        _hover: {
          bg: "blue.700",
          _disabled: {
            bg: "primary.dark",
          },
        },
        _active: {
          bg: "blue.600",
        },
      };
    },

    secondary: {
      color: "white",
      bg: "orange.400",
      borderRadius: "full",
    },
    orangePill: {
      color: "white",
      bg: "orange.400",
      borderRadius: "full",
      borderWidth: "1px",
      borderColor: "transparent",
      textTransform: "none",
      _active: {
        bg: "orange.500",
      },
    },

    outlinePill: {
      color: "white",
      bg: "transparent.20",
      borderRadius: "full",
      borderWidth: "1px",
      borderColor: "white",
      textTransform: "none",
      _active: {
        bg: "#00000090",
      },
    },

    splitOutlinePill: {
      color: "white",
      bg: "transparent.20",
      borderRadius: "full",
      borderWidth: "1px",
      borderColor: "orange.500",
      textTransform: "none",
      _active: {
        bg: "#00000090",
      },
    },

    borderlessGray: {
      color: "gray.100",
      _hover: {
        background: "transparent",
      },
      _active: {
        color: "gray.600",
      },
    },

    outlineBlue: {
      border: "1px",
      borderColor: "primary.dark",
      color: "blue.800",
      _hover: {
        background: "transparent",
      },
      _active: {
        color: "blue.600",
      },
    },
  },
  defaultProps: {
    size: "md",
    height: "7",
  },
};

export default Button;
