const Select = {
  variants: {
    outline: {
      field: {
        borderColor: "blue.300",
        _hover: {
          color: "gray.700",
        },
      },
    },
  },
};

export default Select;
